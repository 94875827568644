import React from 'react';
import MoneyBoxImageSource from './images/money-box.svg';
import MedalImageSource from './images/medal.svg';
import { TwoColumnList } from '../../shared/TwoColumnList'

const items = [
  {
    image: MoneyBoxImageSource,
    title: 'Free shipping',
    description: 'Be one of the first of our limited foundation members to sign up and receive free shipping for 10 parcels. Simply use the code we send you to take advantage of this offer.',
  },
  {
    image: MedalImageSource,
    title: 'Loyalty discounts',
    description: 'Foundation members also get every 7th parcel shipped free for the life of their membership, which means you’ll save even more than 30% over traditional couriers.',
  },
];

const BusinessesSavingsGuarantee = () => <TwoColumnList items={items}/>;

export { BusinessesSavingsGuarantee };