import React from 'react';
import { PageHeader, PageTitle, PageDescription } from '../../shared/Page';
import { Link } from '../../shared/Link';

const BusinessesSavingsSignUpPageHeader = () => (
  <PageHeader>
    <PageTitle>Sign up now and become a foundation member</PageTitle>
  </PageHeader>
);

export { BusinessesSavingsSignUpPageHeader };