import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Layout } from '../../components/shared/layout';
import { Page } from '../../components/shared/Page';
import {
  BusinessesSavingsBanner,
  BusinessesSavingsPageHeader,
  BusinessesSavingsSignUpPageHeader,
} from '../../components/modules/BusinessesSavingsPage';
import { DownloadMerchantsApp } from '../../components/modules/common/DownloadHandCarryApp';
import { BusinessNavigation } from '../../components/modules/common/BusinessNavigation';
import { BusinessesSavingsGuarantee } from '../../components/modules/BusinessesSavingsPage/BusinessesSavingsGuarantee';
import { LocationProp } from '../../components/shared/props/LocationProp';
import { BusinessesSavingsPricing } from '../../components/modules/BusinessesSavingsPage/BusinessesSavingsPricing'

const StyledBusinessSavingsPage = styled(Layout)`
  text-align: center;
`;

const BusinessSavingsPage = ({ location: { pathname } }) => (
  <StyledBusinessSavingsPage headerTransparent>
    <Page>
      <BusinessesSavingsBanner />
      <BusinessesSavingsPageHeader />
      <BusinessesSavingsPricing />
      <BusinessesSavingsSignUpPageHeader />
      <BusinessesSavingsGuarantee />
      <BusinessNavigation pathname={pathname} />
      <DownloadMerchantsApp />
    </Page>
  </StyledBusinessSavingsPage>
);

BusinessSavingsPage.propTypes = {
  location: PropTypes.shape(LocationProp).isRequired,
};


export default BusinessSavingsPage;
