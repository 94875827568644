import React from 'react';
import styled from 'styled-components';
import {
  Table, TableCell, TableRow, TableHeader,
} from '../../shared/Table';

const StyledBusinessesSavingsPricing = styled.div`
`;

const BusinessesSavingsPricing = () => (
  <StyledBusinessesSavingsPricing>
    <Table>
      <thead>
        <TableRow>
          <TableHeader left>Parcel size</TableHeader>
          <TableHeader>0-40</TableHeader>
          <TableHeader>0-60</TableHeader>
          <TableHeader>60-80</TableHeader>
          <TableHeader>80-100</TableHeader>
        </TableRow>
      </thead>
      <tbody>
        <TableRow>
          <TableCell left>Courier</TableCell>
          <TableCell>N/A</TableCell>
          <TableCell>$10.88</TableCell>
          <TableCell>$13.48</TableCell>
          <TableCell>$16.33</TableCell>
        </TableRow>
        <TableRow>
          <TableCell left>Japan Post</TableCell>
          <TableCell>N/A</TableCell>
          <TableCell>$9.60</TableCell>
          <TableCell>$12.12</TableCell>
          <TableCell>$15.12</TableCell>
        </TableRow>
        <TableRow>
          <TableCell left>Hand Carry</TableCell>
          <TableCell bold>
            $6.80
          </TableCell>
          <TableCell bold>
            $8.40
          </TableCell>
          <TableCell bold>
            $10.80
          </TableCell>
          <TableCell bold>
            $12.85
          </TableCell>
        </TableRow>
      </tbody>
    </Table>
  </StyledBusinessesSavingsPricing>
);

export { BusinessesSavingsPricing };