import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle, WatchVideoBannerButton,
} from '../../shared/Banner';
import { PageImage } from '../../shared/PageImage';
import BusinessesSavingsBannerIconSource from './images/businessSavingsBannerIcon.svg';
import { STYLES } from '../../../style';

const StyledBusinessesSavingsBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
`;

const BusinessesSavingsBanner = () => (
  <StyledBusinessesSavingsBanner color={STYLES.PAGE_BUSINESSES_SAVINGS_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={BusinessesSavingsBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>EVERY DOLLAR COUNTS</BannerTitle>
        <BannerDescription>
          We know the service to your customers is paramount.
          Imagine upgrading your customer service experience while adding
          more to your bottom line.
        </BannerDescription>
        <WatchVideoBannerButton />
      </BannerContentItem>
    </BannerContent>
  </StyledBusinessesSavingsBanner>
);

export { BusinessesSavingsBanner };